import { Box, Typography, makeStyles, Grid } from '@material-ui/core'
import React from 'react'

import { useLanguageContext } from 'components/LanguageContext'

import introImg from './assets/intro.png'

const useStyles = makeStyles(theme => ({
  introRootSmall: {
    display: 'none',
    background: `url(${introImg.src}) no-repeat center`,
    backgroundSize: 'cover',
    backgroundPositionX: 0,
    color: '#fff',
    minHeight: 250,
    minWidth: 287,
    borderRadius: 30,
    padding: '60px 73px 0 73px',
    [theme.breakpoints.down('700')]: {
      display: 'block',
      background: theme.palette.primary.main,
      padding: 35,
      minHeight: 'unset',
    },
    [theme.breakpoints.down('320')]: {
      minWidth: 'unset',
    },
  },
  introRootLarge: {
    [theme.breakpoints.down('700')]: {
      display: 'none',
    },
  },
  introMessage: {
    color: '#fff',
    background: theme.palette.primary.main,
    maxWidth: 500,
    padding: 30,
    borderRadius: 30,
    [theme.breakpoints.down('1170')]: {
      maxWidth: 300,
      padding: 18,
    },
  },
  subtitle: {
    color: '#fff',
    fontStyle: 'italic',
    marginTop: 20,
    maxWidth: 650,
  },
  hero: {
    background: `url(${introImg.src}) no-repeat center`,
    backgroundPositionX: 0,
    backgroundSize: 'cover',
    height: 250,
    flexGrow: 0.98,
    padding: '60px 73px 0 73px',
    borderRadius: 30,
  },
}))

export default function Introduction() {
  const { i18n } = useLanguageContext()
  const classes = useStyles()
  return (
    <>
      <Box className={classes.introRootSmall}>
        <Typography variant="h1">{i18n.introduction.title}</Typography>
        <Typography className={classes.subtitle} variant="h6">
          {i18n.introduction.subtitle}
        </Typography>
      </Box>
      <Grid
        className={classes.introRootLarge}
        container
        justifyContent="space-between"
      >
        <Grid className={classes.introMessage}>
          <Typography variant="h1">{i18n.introduction.title}</Typography>
          <Typography className={classes.subtitle} variant="h6">
            {i18n.introduction.subtitle}
          </Typography>
        </Grid>
        <Grid className={classes.hero}></Grid>
      </Grid>
    </>
  )
}
