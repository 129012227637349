import { Box, Link, Typography, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Image from 'next/image'
import React from 'react'

import { useDialog } from 'components/DialogsContext'
import { useLanguageContext } from 'components/LanguageContext'
import IconPrivacy from './assets/icon_privacy.png'
import LogoSSI from './assets/logo_ssi.png'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    margin: '0 35px',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  text: {
    'opacity': 0.5,
    'marginLeft': 10,
    'cursor': 'pointer',
    '& a': {
      color: '#000',
      textDecoration: 'none',
    },
  },
  textSmallScreen: {
    [theme.breakpoints.down(700)]: {
      display: 'none',
    },
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    order: 1,
    [theme.breakpoints.down(700)]: {
      order: 0,
      flexDirection: 'column',
    },
  },
  privacyPolicy: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function Footer() {
  const { i18n } = useLanguageContext()
  const { openContactDialog, openPrivacyPolicyDialog, openSubscriptionDialog } =
    useDialog()
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.actionsContainer}>
        <Box className={classes.privacyPolicy}>
          <Image src={IconPrivacy} width={14} height={15} alt="privacy-icon" />
          <Typography
            variant="caption"
            className={classes.text}
            onClick={openPrivacyPolicyDialog}
          >
            {i18n.menu.privacy}
          </Typography>
        </Box>

        <Typography
          variant="caption"
          className={clsx({
            [classes.text]: true,
            [classes.textSmallScreen]: true,
          })}
        >
          |
        </Typography>
        <Typography
          variant="caption"
          className={classes.text}
          onClick={openContactDialog}
        >
          {i18n.footer.contact}
        </Typography>
        <Typography
          variant="caption"
          className={clsx({
            [classes.text]: true,
            [classes.textSmallScreen]: true,
          })}
        >
          |
        </Typography>
        <Typography
          variant="caption"
          className={classes.text}
          onClick={openSubscriptionDialog}
        >
          {i18n.footer.subscripion}
        </Typography>
      </Box>
      <Link href={i18n.footer.siteUrl} target="_blank">
        <Box>
          <Image src={LogoSSI} width={250} height={49} alt="ssi-icon" />
        </Box>
      </Link>
    </Box>
  )
}
