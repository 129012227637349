import { Box, Grid, Typography, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { useQuery } from 'react-query'

import Api from 'api'
import { useLanguageContext } from 'components/LanguageContext'
import StyledImage from 'components/StyledImage'
import { useTipFilter } from 'components/TipFilterContext'
import categories from './categories'

const useStyles = makeStyles(theme => ({
  button: {
    display: 'flex',
    border: 'none',
    width: '100%',
  },
  active: {
    backgroundColor: 'white !important',
    color: ({ color }) => color,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      '&:after': {
        backgroundColor: ({ color }) => color,
        borderRadius: 10,
        content: "''",
        display: 'block',
        height: 5,
        position: 'absolute',
        top: 120,
        width: 100,
      },
    },
  },
  categoryTab: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300,
    },
  },
  iconContainer: {
    marginBottom: 10,
    width: 50,
    height: 50,
  },
  categoryTitle: {
    [theme.breakpoints.down('321')]: {
      fontSize: 14,
    },
  },
  tab: {
    'flex': 1,
    'alignItems': 'center',
    'backgroundColor': ({ color }) => color,
    'borderRadius': 30,
    'color': 'white',
    'cursor': 'pointer',
    'display': 'flex',
    'flexDirection': 'column',
    'height': 111,
    'justifyContent': 'flex-end',
    'paddingBottom': 10,
    'textAlign': 'center',
    'transition': 'all 200ms',
    '&:hover': {
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.6)',
      transition: 'all 200ms',
    },
  },
  title: {
    marginLeft: 12,
    marginBottom: 12,
  },
}))

function CategoryButton({ category, isSelected, onCategoryPress }) {
  const { isSpanish } = useLanguageContext()
  const icon = isSelected ? category.iconActive : category.icon

  const classes = useStyles({ color: category.color })

  return (
    <button
      onClick={() => onCategoryPress(category)}
      className={classes.button}
    >
      <Box
        id={`category_${category.id}`}
        className={clsx({
          [classes.tab]: true,
          [classes.active]: isSelected,
        })}
      >
        <StyledImage containerClass={classes.iconContainer} src={icon} />
        <Typography variant="h6" className={classes.categoryTitle}>
          {isSpanish ? category.title_es : category.title}
        </Typography>
      </Box>
    </button>
  )
}

export default function Categories() {
  const { i18n } = useLanguageContext()
  const classes = useStyles()

  const { data: categoriesData } = useQuery({
    queryKey: ['categories'],
    queryFn: Api.getCategories,
  })

  const { selectedCategory, onCategoryChange } = useTipFilter()

  const onCategoryPress = React.useCallback(
    category => {
      if (selectedCategory && selectedCategory.id === category.id) {
        onCategoryChange(null)
        return
      }

      const categoryInfo = categoriesData?.categories.find(
        x => x.id === category?.id
      )

      onCategoryChange({ ...category, guide: categoryInfo?.guide })
    },
    [selectedCategory, onCategoryChange, categoriesData]
  )

  const renderCategory = React.useCallback(
    category => (
      <Grid
        key={category.id}
        item
        xs={6}
        sm={4}
        md={2}
        className={classes.categoryTab}
      >
        <CategoryButton
          category={category}
          isSelected={selectedCategory && selectedCategory.id === category.id}
          onCategoryPress={onCategoryPress}
        />
      </Grid>
    ),
    [selectedCategory, classes, onCategoryPress]
  )

  return (
    <>
      <Typography variant="h3" color="textSecondary" className={classes.title}>
        {i18n.categories}
      </Typography>
      <Grid container spacing={1} justifyContent="center">
        {Object.values(categories).map(renderCategory)}
      </Grid>
    </>
  )
}
