import Alert from '@material-ui/lab/Alert'
import {
  Box,
  CircularProgress,
  IconButton,
  Input,
  LinearProgress,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Close as ClearSearch } from '@material-ui/icons'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import Image from 'next/image'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery } from 'react-query'

import Api from 'api'
import { mapCategoryToColor } from 'components/Categories/categories'
import { useDialog } from 'components/DialogsContext'
import { useLanguageContext } from 'components/LanguageContext'
import parseHtml from 'components/parseHtml'
import Tip from 'components/Tip'
import { useTipFilter } from 'components/TipFilterContext'

import IconSearch from './assets/icon_search.png'

const useStyles = makeStyles(theme => ({
  alert: {
    width: '100%',
    marginBottom: 10,
    borderRadius: 15,
    cursor: 'pointer',
  },
  alertHover: {
    'transition': 'all 200ms',
    '&:hover': {
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
      transition: 'all 200ms',
    },
  },
  disabled: {
    opacity: 0.5,
    cursor: 'unset',
  },
  headerContainer: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  searchContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    overflow: 'hidden',
  },
  innerContainer: {
    height: '100%',
    overflowY: 'auto',
  },
  innerContainerLoading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  outerContainer: {
    backgroundColor: 'white',
    borderRadius: 30,
    height: 360,
    padding: '20px 10px 20px 20px',
    display: 'flex',
    flexDirection: 'column',
  },

  input: {
    backgroundColor: 'white',
    borderRadius: 15,
    color: '#000',
    height: 35,
    paddingLeft: 10,
    width: 191,
    transition: 'width 200ms ease-out',
    [theme.breakpoints.down(600)]: {
      width: '100%',
      marginTop: 10,
    },
  },
  searchFocused: {
    width: '100%',
    transition: 'width 200ms ease-out',
  },
  searchButton: {
    'backgroundColor': theme.palette.primary.main,
    'borderRadius': 15,
    'height': 31,
    'padding': 7,
    'width': 34,
    'cursor': 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
    [theme.breakpoints.down(600)]: {
      width: 33,
    },
  },
  noTips: {
    margin: 20,
  },
  title: {
    'margin': '0 12px',
    'maxWidth': 400,
    '& .categoryTitle': {
      color: ({ color }) => color,
    },
    [theme.breakpoints.down(1200)]: {
      maxWidth: 350,
      textOverflow: 'ellipsis',
    },
    [theme.breakpoints.down(1100)]: {
      maxWidth: 300,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 400,
    },
    [theme.breakpoints.down(650)]: {
      maxWidth: 300,
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: 'unset',
      marginBottom: ({ categorySelected }) => (categorySelected ? 20 : 0),
    },
  },
  titleEs: {
    [theme.breakpoints.between(960, 1030)]: {
      fontSize: 19,
      maxWidth: 265,
    },
  },
  clearSelection: {
    textDecoration: 'underline',
    position: 'absolute',
    left: 13,
    top: 32,
    cursor: 'pointer',
  },
}))

export default function Tips() {
  const { i18n, isSpanish, language } = useLanguageContext()
  const {
    selectedCategory,
    searchText,
    setSearchText,
    clearCategorySelection,
  } = useTipFilter()

  const { openCategoryDialog } = useDialog()

  const [searchQuery, setSearchQuery] = React.useState('')

  React.useEffect(() => {
    setSearchQuery('')
  }, [selectedCategory])

  const {
    isLoading: isLoadingTips,
    data: tipsData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['tips', language, selectedCategory?.id, searchQuery],
    queryFn: async ({ pageParam }) =>
      Api.getTips({
        offset: pageParam,
        language,
        search: searchQuery,
        categoryId: selectedCategory?.id,
      }),
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.total <= allPages.length * 20) {
        return undefined
      }
      return allPages.length * 20
    },
  })

  const tips = React.useMemo(() => {
    return !tipsData
      ? {
          items: [],
          count: 0,
        }
      : {
          items: [].concat(...tipsData.pages.map(page => page.items)),
          count: Number(tipsData.pages[0].total),
        }
  }, [tipsData])

  const handleCategoryAlertClick = React.useCallback(() => {
    if (isLoadingTips) {
      return
    }
    openCategoryDialog(selectedCategory)
  }, [selectedCategory, isLoadingTips, openCategoryDialog])

  const debouncedQueryChange = React.useMemo(() => {
    return debounce(
      text => {
        setSearchQuery(text)
      },
      1500,
      { maxWait: 2500 }
    )
  }, [])

  const onChangeSearchText = React.useCallback(
    e => {
      const {
        target: { value },
      } = e

      const newValue = value.trim()
      setSearchText(value.replace(/\r/gm, ' ').replace(/\n/gm, ' '))

      document.getElementById('tips-container').scrollTo(0, 0)

      // Cancel any delayed searches and reset search query
      if (newValue === '') {
        debouncedQueryChange.cancel()
        setSearchQuery('')
        return
      }

      debouncedQueryChange(newValue)
    },
    [debouncedQueryChange, setSearchQuery, setSearchText]
  )

  const tipsTitle = React.useMemo(() => {
    if (!selectedCategory) {
      return i18n.search.getTips
    }

    const categoryTitle = isSpanish
      ? selectedCategory.selected_title_es
      : selectedCategory.selected_title

    return (
      <>
        {i18n.search.getTipsFor}
        <span className="categoryTitle">{categoryTitle}</span>
      </>
    )
  }, [selectedCategory, isSpanish, i18n])

  const handleSubmit = React.useCallback(e => {
    e.preventDefault()
  }, [])

  const classes = useStyles({
    color: selectedCategory ? selectedCategory.color : '#fff',
    categorySelected: !!selectedCategory,
  })

  const renderTip = React.useCallback(
    (tip, index) => (
      <Tip
        key={tip.id}
        id={`tip_${index}`}
        color={mapCategoryToColor[tip.category.parentName]}
        index={index}
        showDivider={index < tips.items.length - 1}
        tip={tip}
      />
    ),
    [tips]
  )

  const renderTips = React.useCallback(() => {
    if (isLoadingTips) {
      return null
    }

    if (tips.items.length === 0) {
      return (
        <Typography
          variant="subtitle2"
          color="textPrimary"
          className={classes.noTips}
        >
          {parseHtml(i18n.search.noTipsFound)}
        </Typography>
      )
    }

    return (
      <InfiniteScroll
        dataLength={tips.items.length}
        next={fetchNextPage}
        hasMore={hasNextPage}
        scrollableTarget="tips-container"
        loader={<LinearProgress />}
      >
        {tips.items.map(renderTip)}
      </InfiniteScroll>
    )
  }, [
    isLoadingTips,
    tips,
    classes,
    i18n,
    renderTip,
    fetchNextPage,
    hasNextPage,
  ])

  return (
    <>
      <Box className={classes.headerContainer}>
        <Box width={1}>
          <Typography
            variant="h3"
            color="textSecondary"
            className={clsx({
              [classes.title]: true,
              [classes.titleEs]: isSpanish,
            })}
            noWrap
          >
            {tipsTitle}
          </Typography>
          {selectedCategory && (
            <Typography
              variant="subtitle2"
              color="textSecondary"
              className={classes.clearSelection}
              noWrap
              onClick={clearCategorySelection}
            >
              {i18n.tip.clearSelection}
            </Typography>
          )}
        </Box>
        <Box className={classes.searchContainer} id="tipSearch">
          <Input
            value={searchText}
            onChange={onChangeSearchText}
            className={classes.input}
            classes={{
              focused: classes.searchFocused,
            }}
            disableUnderline
            placeholder={i18n.search.title}
            endAdornment={
              !searchText.length ? (
                <Box
                  className={classes.searchButton}
                  type="submit"
                  color="primary"
                  onClick={handleSubmit}
                >
                  <Image src={IconSearch} alt="search-icon" />
                </Box>
              ) : (
                <IconButton
                  size="small"
                  onClick={() =>
                    onChangeSearchText({
                      target: { value: '' },
                    })
                  }
                >
                  <ClearSearch />
                </IconButton>
              )
            }
          />
        </Box>
      </Box>

      <Box className={classes.outerContainer}>
        {selectedCategory && (
          <Alert
            id="categoryInfo"
            severity="info"
            className={clsx({
              [classes.alert]: true,
              [classes.alertHover]: !isLoadingTips,
              [classes.disabled]: isLoadingTips,
            })}
            onClick={handleCategoryAlertClick}
          >
            {i18n.category.top10guide}
          </Alert>
        )}
        <Box
          id="tips-container"
          className={clsx({
            [classes.innerContainer]: true,
            [classes.innerContainerLoading]: isLoadingTips,
          })}
        >
          {renderTips()}
          {isLoadingTips && <CircularProgress />}
        </Box>
      </Box>
    </>
  )
}
