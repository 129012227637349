import { makeStyles } from '@material-ui/core'
import Head from 'next/head'
import React from 'react'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: '#ecf3fd',
    padding: 20,
  },
  content: {
    flex: 1,
  },
})

export default function Layout({ children, pageProps = {} }) {
  const classes = useStyles()

  const { opengraph, title } = pageProps

  const openGraphTags =
    opengraph && opengraph.length > 0
      ? opengraph.map(({ key, content }) => (
          <meta key={key} property={`og:${key}`} content={`${content}`} />
        ))
      : []

  return (
    <>
      <Head>
        {title && <title>{title}</title>}
        {openGraphTags}
      </Head>
      <div className={classes.container}>
        <div className={classes.content}>{children}</div>
      </div>
    </>
  )
}
