import {
  Box,
  Button,
  Grid,
  Link,
  Typography,
  makeStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import Image from 'next/image'
import React from 'react'

import { useDialog } from 'components/DialogsContext'
import Help from 'components/Help'
import { useLanguageContext } from 'components/LanguageContext'
import ShareSocial from 'components/ShareSocial'
import MobileNavigation from './MobileNavigation'

import IconDonate from './assets/icon_donate.png'
import IconDonateHover from './assets/icon_donate_hover.png'
import IconHelp from './assets/icon_help.png'
import IconHelpHover from './assets/icon_help_hover.png'
import IconInfo from './assets/icon_info.png'
import IconInfoHover from './assets/icon_info_hover.png'
import IconScan from './assets/icon_scan.png'
import IconShare from './assets/icon_share.png'
import IconShareHover from './assets/icon_share_hover.png'
import Logo from './assets/logo.svg'

const useStyles = makeStyles(theme => ({
  mobileNav: {
    display: 'none',
    [theme.breakpoints.down('1170')]: {
      display: 'unset',
    },
  },
  desktopNav: {
    [theme.breakpoints.down('1170')]: {
      display: 'none',
    },
  },
  actionsContainer: {
    alignItems: 'center',
  },
  logo: {
    height: 100,
    [theme.breakpoints.down('lg')]: {
      height: 75,
    },
  },
  itemContainer: {
    'alignItems': 'center',
    'color': theme.palette.text.navBar,
    'cursor': 'pointer',
    'display': 'flex',
    'transition': 'all 200ms',
    [theme.breakpoints.down(1305)]: {
      transition: 'all 200ms',
    },
    '&:hover': {
      'color': theme.palette.primary.main,
      '& > .infoIcon': {
        backgroundImage: `url(${IconInfoHover.src})`,
      },
      '& > .shareIcon': {
        backgroundImage: `url(${IconShareHover.src})`,
      },
      '& > .helpIcon': {
        backgroundImage: `url(${IconHelpHover.src})`,
      },
      '& > .donateIcon': {
        backgroundImage: `url(${IconDonateHover.src})`,
      },
    },
    '& .infoIcon': {
      backgroundImage: `url(${IconInfo.src})`,
      width: 25,
      height: 25,
    },
    '& .shareIcon': {
      backgroundImage: `url(${IconShare.src})`,
      width: 25,
      height: 23,
    },
    '& .helpIcon': {
      backgroundImage: `url(${IconHelp.src})`,
      width: 25,
      height: 26,
    },
    '& .donateIcon': {
      backgroundImage: `url(${IconDonate.src})`,
      width: 25,
      height: 25,
    },
  },
  languageText: {
    'position': 'relative',
    '&:after': {
      content: "''",
      width: 21,
      borderBottom: '1px solid',
      borderColor: theme.palette.text.primary,
      position: 'absolute',
      transition: 'all 200ms',
      top: 20,
    },
  },
  languageTextEn: {
    '&:after': {
      transition: 'all 200ms',
      left: 0,
    },
  },
  languageTextEs: {
    '&:after': {
      transition: 'all 200ms',
      left: 32,
    },
  },
  text: {
    marginLeft: 10,
  },
  scanButton: {
    borderRadius: '24px !important',
    textTransform: 'unset',
    width: 193,
    lineHeight: '14px',
    minHeight: 37,
    transition: 'all 200ms',
    [theme.breakpoints.down(1305)]: {
      transition: 'all 200ms',
      width: 153,
    },
  },
  button: {
    border: 'none',
    background: 'none',
    marginLeft: 10,
    marginRight: 30,
    [theme.breakpoints.down(1305)]: {
      marginRight: 10,
    },
  },
}))

function Navigation({ onHelp }) {
  const { openAboutDialog, openScanProductDialog } = useDialog()
  const { i18n, language, toggleLanguage } = useLanguageContext()
  const classes = useStyles()
  return (
    <Grid container wrap="nowrap">
      <Grid item container md={3} alignItems="center" wrap="nowrap">
        {/* 
                    next/image not used since !important must be used on the components rendered by the package, which is not favourable, 
                    in order to accommodate our needs; a responsive image with fixed height based on the screen layout - 
                    next/image overrides the styles by using inline styling
                */}
        {
          // eslint-disable-next-line @next/next/no-img-element
        }
        <img src={Logo.src} className={classes.logo} alt="logo" />
      </Grid>
      <Grid
        container
        item
        className={classes.actionsContainer}
        justifyContent="flex-end"
        md={9}
      >
        <button onClick={openAboutDialog} className={classes.button}>
          <Box className={classes.itemContainer}>
            <Box className="infoIcon"></Box>
            <Typography className={classes.text} variant="h6">
              {i18n.menu.about}
            </Typography>
          </Box>
        </button>

        <ShareSocial
          top={10}
          left={-10}
          shareText={i18n.sharing.shareAppMessage}
        >
          <Box id="share" className={classes.itemContainer}>
            <Box className="shareIcon"></Box>
            <Typography className={classes.text} variant="h6">
              {i18n.menu.share}
            </Typography>
          </Box>
        </ShareSocial>

        <button onClick={onHelp} className={classes.button}>
          <Box className={classes.itemContainer}>
            <Box className="helpIcon"></Box>
            <Typography className={classes.text} variant="h6">
              {i18n.menu.help}
            </Typography>
          </Box>
        </button>

        <Link underline="none" target="_blank" href={i18n.menu.donateUrl}>
          <Box id="donate" className={classes.itemContainer}>
            <Box className="donateIcon"></Box>
            <Typography className={classes.text} variant="h6">
              {i18n.menu.donate}
            </Typography>
          </Box>
        </Link>

        <button onClick={toggleLanguage} className={classes.button}>
          <Box className={classes.itemContainer}>
            <Typography
              variant="h6"
              className={clsx({
                [classes.languageText]: true,
                [classes.languageTextEn]: language === 'en',
                [classes.languageTextEs]: language === 'es',
              })}
            >
              EN | ES
            </Typography>
          </Box>
        </button>

        <Button
          id="scan"
          className={classes.scanButton}
          variant="contained"
          color="primary"
          endIcon={<Image src={IconScan} alt="scan-icon" />}
          onClick={openScanProductDialog}
        >
          {i18n.menu.scanProductBtn}
        </Button>
      </Grid>
    </Grid>
  )
}

export default function NavBar() {
  const helpRef = React.useRef()

  const onHelp = React.useCallback(() => {
    helpRef.current.start()
  }, [helpRef])

  const classes = useStyles()

  return (
    <>
      <Help ref={helpRef} />
      <Box className={classes.mobileNav}>
        <MobileNavigation onHelp={onHelp} />
      </Box>
      <Box className={classes.desktopNav}>
        <Navigation onHelp={onHelp} />
      </Box>
    </>
  )
}
