import { Grid, makeStyles } from '@material-ui/core'
import truncate from 'lodash/truncate'
import { useRouter } from 'next/router'
import React from 'react'

import Categories from './Categories'
import { mapCategoryToColor } from './Categories/categories'
import { useDialog } from './DialogsContext'
import Feed from './Feed'
import Footer from './Footer'
import Introduction from './Introduction'
import { useLanguageContext } from './LanguageContext'
import Layout from './Layout'
import NavBar from './NavBar'
import Tips from './Tips'
import slugify from './slugify'
import { getNewsFeedTexts } from './utils'

const useStyles = makeStyles(theme => ({
  '@global': {
    'body': {
      backgroundColor: 'unset',
    },
    '*::-webkit-scrollbar': {
      width: 7,
    },
    '*::-webkit-scrollbar-track': {
      background: 'rgb(241, 241, 241)',
      borderRadius: 5,
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#BFD4F4',
      borderRadius: 5,
    },
  },
  'marginTop30': {
    marginTop: 30,
  },
  'tipsFeedContainer': {
    marginTop: 50,
    [theme.breakpoints.down('xs')]: {
      marginTop: 30,
    },
  },
  'tipsContainer': {
    paddingRight: 5,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },
  'newsFeedContainer': {
    paddingLeft: 5,
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  'footerContainer': {
    marginTop: 25,
  },
}))

function getNewsFeedText(newsFeed, language) {
  // fall back to English if text is not found for given language
  return (
    newsFeed.texts.find(x => x.language === language) ||
    newsFeed.texts.find(x => x.language === 'en')
  )
}

function getPageUrl(language, type, id, title) {
  return `${
    process.env.NEXT_PUBLIC_BASE_URL
  }/${language}/${type}/${id}/${slugify(title)}`
}

function setSlugUrl(router, language, type, id, title) {
  router.push(`${type}/${id}/${slugify(title)}`, undefined, {
    locale: language,
    shallow: true,
  })
}

export default function Home({ tip, feed }) {
  const { openFeedDialog, openTipDialog } = useDialog()
  const { i18n, isSpanish, language } = useLanguageContext()
  const router = useRouter()

  React.useEffect(() => {
    if (!tip && !feed) {
      router.push('/', undefined, {
        locale: language,
        shallow: true,
      })
    }

    if (tip) {
      const title = isSpanish ? tip.tip_es : tip.tip
      setSlugUrl(router, language, 'tips', tip.id, title)

      openTipDialog({
        ...tip,
        color: mapCategoryToColor[tip.category.parentName],
      })
    }

    if (feed) {
      const feedText = getNewsFeedText(feed, language)
      const feedItem = {
        ...feed,
        title: feedText.push_title,
        content: feedText.push_body,
      }

      setSlugUrl(router, language, 'news', feedItem.id, feedItem.title)

      openFeedDialog(feedItem)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tip, feed, isSpanish, language, openFeedDialog, openTipDialog])

  const pageProps = React.useMemo(() => {
    if (tip) {
      const title = isSpanish ? tip.tip_es : tip.tip
      const description = truncate(isSpanish ? tip.why_es : tip.why, {
        length: 200,
      })

      return {
        title,
        opengraph: [
          {
            key: 'title',
            content: title,
          },
          {
            key: 'description',
            content: description,
          },
          {
            key: 'url',
            content: getPageUrl(language, 'tips', tip.id, title),
          },
          {
            key: 'twitter:title',
            content: title,
          },
          {
            key: 'twitter:description',
            content: description,
          },
        ],
      }
    }

    if (feed) {
      const texts = getNewsFeedTexts(feed, language)
      const title = texts.push_title
      const description = truncate(texts.push_body, { length: 200 })

      return {
        title,
        opengraph: [
          {
            key: 'title',
            content: title,
          },
          {
            key: 'description',
            content: description,
          },
          {
            key: 'url',
            content: getPageUrl(language, 'news', feed.id, title),
          },
          {
            key: 'twitter:title',
            content: title,
          },
          {
            key: 'twitter:description',
            content: description,
          },
        ],
      }
    }

    return {
      opengraph: [
        {
          key: 'title',
          content: i18n.home.title,
        },
        {
          key: 'description',
          content: i18n.home.description,
        },
        {
          key: 'twitter:title',
          content: i18n.home.title,
        },
        {
          key: 'twitter:description',
          content: i18n.home.description,
        },
      ],
    }
  }, [isSpanish, language, feed, i18n, tip])

  const classes = useStyles()

  return (
    <Layout pageProps={pageProps}>
      <Grid container>
        <Grid item xs={12}>
          <NavBar />
        </Grid>
        <Grid item xs={12} className={classes.marginTop30}>
          <Introduction />
        </Grid>
        <Grid item xs={12} className={classes.marginTop30}>
          <Categories />
        </Grid>
        <Grid item container xs={12} className={classes.tipsFeedContainer}>
          <Grid item xs={12} sm={12} md={6} className={classes.tipsContainer}>
            <Tips />
          </Grid>
          <Grid
            id="newsFeed"
            item
            xs={12}
            sm={12}
            md={6}
            className={classes.newsFeedContainer}
          >
            <Feed />
          </Grid>
          <Grid
            container
            item
            justifyContent="flex-end"
            sm={12}
            className={classes.footerContainer}
          >
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}
