import { useMediaQuery } from '@material-ui/core'
import dynamic from 'next/dynamic'
import React from 'react'

import { useDialog } from 'components/DialogsContext'
import { useLanguageContext } from 'components/LanguageContext'

import 'intro.js/introjs.css'

const DynamicSteps = dynamic(() => import('./Steps').then(mod => mod.default), {
  ssr: false,
})

const Steps = React.forwardRef(function DynamicStepsComponent(props, ref) {
  return <DynamicSteps {...props} forwardedRef={ref} />
})

function getSteps(data, isMobileNav) {
  return [
    {
      title: data.title,
      intro: data.identify,
    },
    {
      element: isMobileNav ? '#share-mobile' : '#share',
      intro: data.share,
    },
    {
      element: isMobileNav ? '#donate-mobile' : '#donate',
      intro: data.donate,
    },
    {
      element: isMobileNav ? '#scan-mobile' : '#scan',
      intro: data.scan,
    },
    {
      element: '#category_649',
      intro: data.selectCategory,
    },
    {
      element: '#tipSearch',
      intro: data.tipSearch,
    },
    {
      element: '#categoryInfo',
      intro: data.categoryInfo,
    },
    {
      element: '#tip_0',
      intro: data.tipSelect,
    },
    {
      element: '#newsFeed',
      intro: data.newsContainer,
    },
    {
      element: '#feedToggle',
      intro: data.feedToggle,
    },
    {
      element: '#feed_0',
      intro: data.feedSelect,
    },
    { element: '#readMore', intro: data.feedReadMore, inDialog: true },
  ]
}

function waitForElementsToAppear(elementIds) {
  // Check every 100ms if the element is visible in the DOM
  return new Promise(resolve => {
    const interval = setInterval(function () {
      if (document.querySelectorAll(elementIds).length === elementIds.length) {
        resolve(true)
        clearInterval(interval)
      }
    }, 100)
  })
}

function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

export default React.forwardRef(function Help({}, ref) {
  const { closeFeedDialog } = useDialog()
  const { i18n } = useLanguageContext()
  const [helpEnabled, setHelpEnabled] = React.useState(false)
  const stepsRef = React.useRef()

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('1170'))
  const steps = React.useMemo(() => {
    return getSteps(i18n.help, isSmallScreen)
  }, [i18n, isSmallScreen])

  const start = React.useCallback(() => {
    setHelpEnabled(true)
  }, [])

  React.useImperativeHandle(
    ref,
    () => ({
      start,
    }),
    [start]
  )

  const onExit = React.useCallback(() => {
    // When walkthrough is completed, scroll window to top and close feed dialog if open
    setHelpEnabled(false)
    scrollToTop()
    closeFeedDialog()
  }, [closeFeedDialog])

  const onBeforeChange = React.useCallback(nextStep => {
    // Perform click actions and block excecution

    // Find and click 'Food & Drinks' tab
    if (nextStep >= 5 && !document.getElementById('categoryInfo')) {
      document.getElementById('category_649').click()
      return false
    }

    if (nextStep === 7) {
      const scrollableContainer =
        document.getElementById('tips-container').firstChild
      if (scrollableContainer) {
        scrollableContainer.scrollIntoView()
      }

      return true
    }

    if (nextStep === 10) {
      const scrollableContainer =
        document.getElementById('feed-container').firstChild
      if (scrollableContainer) {
        scrollableContainer.scrollIntoView()
      }
    }

    // Find and click news toggle
    if (
      nextStep >= 10 &&
      !document
        .getElementById('feedToggleSlider')
        .className.includes('shouldHaveTipsActive')
    ) {
      document.getElementById('feedToggle').click()
      return false
    }

    // Find and click first feed item
    if (nextStep === 11 && !document.getElementById('readMore')) {
      document.getElementById('feed_0').click()
      return false
    }
  }, [])

  const continueToNextStep = React.useCallback(
    async (elementIds, previousStep) => {
      // Wait until element is visible in the DOM
      if (elementIds.length > 0) {
        await waitForElementsToAppear(elementIds)
      }

      // Update internally stored elements positioning
      for (const x of Array(steps.length).keys()) {
        stepsRef.current.updateStepElement(x)
      }

      // onBeforeChange will get invoked with nextStep as param
      // Notice here that we are in a paused state and we are at the current step (e.g. 5)
      // Here we have access to the previous step (e.g. 4) and we need to go to next step (e.g. 6 === currentStep + 1 === previousStep + 2)
      stepsRef.current.introJs.goToStepNumber(previousStep + 2)
    },
    [stepsRef, steps]
  )

  const onPreventChange = React.useCallback(
    async previousStep => {
      // When excecution is blocked, wait for elements to be visible if necessary and proceed.
      let elementIds = []

      if (3 < previousStep && previousStep < 9) {
        elementIds = ['#tip_0']
      }

      if ([9, 10].includes(previousStep)) {
        elementIds = ['#tip_0', '#feed_0']
      }

      continueToNextStep(elementIds, previousStep)
    },
    [continueToNextStep]
  )

  const onAfterChange = React.useCallback(
    step => {
      // Manually set the top position of introjs tooltip
      // Tooltip's top positioning depends on window's scroll offset,
      // but the top positioning of elements in a dialog is independent of scroll offset
      // As a result, tooltip in a dialog is placed 'scrollTop' pixels below the actual element

      const offsetTop = element => {
        const inDialog = steps[step].inDialog

        if (inDialog) {
          element.scrollIntoView()
        }

        // Calculate where tooltip should be positioned for elements in and outside a dialog
        const rect = element.getBoundingClientRect()
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop

        return inDialog ? rect.top : rect.top + scrollTop
      }

      // Set tooltip's correct positioning
      const element = document.querySelector(steps[step].element)
      if (element) {
        const offset = offsetTop(element) - 5
        document.querySelector('.introjs-helperLayer').style.top = `${offset}px`
        document.querySelector(
          '.introjs-tooltipReferenceLayer'
        ).style.top = `${offset}px`
      }
    },
    [steps]
  )

  return (
    <Steps
      initialStep={0}
      enabled={helpEnabled}
      steps={steps}
      onExit={onExit}
      options={{
        prevLabel: i18n.help.previous,
        nextLabel: i18n.help.next,
        doneLabel: i18n.help.done,
      }}
      onBeforeChange={onBeforeChange}
      onPreventChange={onPreventChange}
      onAfterChange={onAfterChange}
      ref={stepsRef}
    />
  )
})
