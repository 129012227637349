import IconChildren from './assets/icon_children.png'
import IconChildrenActive from './assets/icon_children_active.png'
import IconCleaning from './assets/icon_cleaning.png'
import IconCleaningActive from './assets/icon_cleaning_active.png'
import IconClothing from './assets/icon_clothing.png'
import IconClothingActive from './assets/icon_clothing_active.png'
import IconFoodAndDrinks from './assets/icon_food_and_drinks.png'
import IconFoodAndDrinksActive from './assets/icon_food_and_drinks_active.png'
import IconHome from './assets/icon_home.png'
import IconHomeActive from './assets/icon_home_active.png'
import IconPersonalCare from './assets/icon_personal_care.png'
import IconPersonalCareActive from './assets/icon_personal_care_active.png'

const mapCategoryToColor = {
  'Children': '#FF9797',
  'Food and Drink': '#FABD78',
  'Home': '#F9669A',
  'Personal Care': '#A550BB',
  'Clothing and Laundry': '#B3CA67',
  'Cleaning': '#46BFA1',
}
export { mapCategoryToColor }

const categories = {
  FOOD_AND_DRINK: {
    id: 649,
    title: 'Food & Drinks',
    title_es: 'Comidas y bebidas',
    selected_title: 'food and drink',
    selected_title_es: 'comidas y bebidas',
    icon: IconFoodAndDrinks,
    iconActive: IconFoodAndDrinksActive,
    color: '#FABD78',
  },
  CHILDREN: {
    id: 650,
    title: 'Children',
    title_es: 'Niños',
    selected_title: 'children',
    selected_title_es: 'niños',
    icon: IconChildren,
    iconActive: IconChildrenActive,
    color: '#FF9797',
  },
  CLOTHING: {
    id: 651,
    title: 'Clothing',
    title_es: 'Ropa y su lavado',
    selected_title: 'clothing',
    selected_title_es: 'ropa',
    icon: IconClothing,
    iconActive: IconClothingActive,
    color: '#B3CA67',
  },
  PERSONAL_CARE: {
    id: 646,
    title: 'Personal Care',
    title_es: 'Cuidado personal',
    selected_title: 'personal care',
    selected_title_es: 'el cuidado personal',
    icon: IconPersonalCare,
    iconActive: IconPersonalCareActive,
    color: '#A550BB',
  },
  HOME: {
    id: 647,
    title: 'Home',
    title_es: 'Hogar',
    selected_title: 'home',
    selected_title_es: 'el hogar',
    icon: IconHome,
    iconActive: IconHomeActive,
    color: '#F9669A',
  },
  CLEANING: {
    id: 648,
    title: 'Cleaning',
    title_es: 'Limpieza',
    selected_title: 'cleaning',
    selected_title_es: 'la limpieza',
    icon: IconCleaning,
    iconActive: IconCleaningActive,
    color: '#46BFA1',
  },
}

export default categories
