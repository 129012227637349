import {
  Box,
  Collapse,
  IconButton,
  Link,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  Menu as MobileMenuIcon,
  Close as CloseNavBarIcon,
} from '@material-ui/icons'
import clsx from 'clsx'
import Image from 'next/image'
import React from 'react'

import { useDialog } from 'components/DialogsContext'
import { useLanguageContext } from 'components/LanguageContext'
import {
  FacebookButton,
  LinkedInButton,
  TwitterButton,
} from 'components/ShareSocial'

import IconDonateWhite from './assets/icon_donate_white.png'
import IconHelpWhite from './assets/icon_help_white.png'
import IconInfoWhite from './assets/icon_info_white.png'
import IconScan from './assets/icon_scan.png'
import IconShareWhite from './assets/icon_share_white.png'
import LogoDMMobile from './assets/logo_dm_mobile.png'

const useStyles = makeStyles(theme => ({
  mobileLanguageText: {
    'marginLeft': 30,
    'position': 'relative',
    'color': 'white',
    '&:after': {
      content: "''",
      width: 25,
      borderBottom: '1px solid',
      borderColor: 'white',
      position: 'absolute',
      transition: 'all 200ms',
      top: 22,
    },
  },
  languageTextEn: {
    '&:after': {
      transition: 'all 200ms',
      left: 0,
    },
  },
  mobileLanguageTextEs: {
    '&:after': {
      transition: 'all 200ms',
      left: 40,
    },
  },
  mobileNavContainer: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 28,
    padding: '5px 10px',
    minWidth: 287,
    [theme.breakpoints.down('320')]: {
      minWidth: 'unset',
    },
  },
  mobileNavHeaderContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  mobileNavActionsContainer: {
    borderTop: '1px solid white',
    marginTop: 20,
  },
  mobileShareActionsContainer: {
    'marginLeft': 34,
    '& a': {
      marginRight: 10,
    },
  },
  mobileActionText: {
    marginLeft: 10,
    color: 'white',
  },
  mobileItemContainer: {
    'alignItems': 'center',
    'cursor': 'pointer',
    'display': 'flex',
    'padding': '20px 10px',
    '&:hover': {
      opacity: 0.8,
    },
  },
  menuIcon: {
    fontSize: 44,
    color: 'white',
    cursor: 'pointer',
  },
  button: {
    width: '100%',
    border: 'none',
    background: 'none',
    padding: 0,
  },
}))

export default function MobileNavigation({ onHelp }) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [shareIsOpen, setShareIsOpen] = React.useState(false)
  const { openAboutDialog, openScanProductDialog } = useDialog()
  const { i18n, language, toggleLanguage } = useLanguageContext()
  const classes = useStyles()
  return (
    <Box className={classes.mobileNavContainer}>
      <Box className={classes.mobileNavHeaderContainer}>
        <Image src={LogoDMMobile} alt="mobile-logo-icon" />
        {!isOpen && (
          <MobileMenuIcon
            className={classes.menuIcon}
            onClick={() => {
              setIsOpen(true)
            }}
          />
        )}
        {isOpen && (
          <IconButton
            className={classes.menuIcon}
            onClick={() => {
              setIsOpen(false)
            }}
          >
            <CloseNavBarIcon />
          </IconButton>
        )}
      </Box>
      <Collapse in={isOpen}>
        <Box className={classes.mobileNavActionsContainer}>
          <button onClick={openAboutDialog} className={classes.button}>
            <Box className={classes.mobileItemContainer}>
              <Image src={IconInfoWhite} alt="info-icon" />
              <Typography className={classes.mobileActionText} variant="h4">
                {i18n.menu.about}
              </Typography>
            </Box>
          </button>
          <button
            onClick={() => setShareIsOpen(open => !open)}
            className={classes.button}
          >
            <Box
              id="share-mobile"
              className={clsx({
                [classes.mobileItemContainer]: true,
                [classes.shareContainer]: true,
              })}
            >
              <Image src={IconShareWhite} alt="share-icon" />
              <Typography className={classes.mobileActionText} variant="h4">
                {i18n.menu.share}
              </Typography>
            </Box>
          </button>
          <Collapse in={shareIsOpen}>
            <Box className={classes.mobileShareActionsContainer}>
              <FacebookButton shareText={i18n.sharing.shareAppMessage} />
              <LinkedInButton shareText={i18n.sharing.shareAppMessage} />
              <TwitterButton shareText={i18n.sharing.shareAppMessage} />
            </Box>
          </Collapse>
          <button onClick={onHelp} className={classes.button}>
            <Box className={classes.mobileItemContainer} onClick={onHelp}>
              <Image src={IconHelpWhite} alt="help-icon" />
              <Typography className={classes.mobileActionText} variant="h4">
                {i18n.menu.help}
              </Typography>
            </Box>
          </button>
          <Link underline="none" target="_blank" href={i18n.menu.donateUrl}>
            <Box id="donate-mobile" className={classes.mobileItemContainer}>
              <Image src={IconDonateWhite} alt="donate-icon" />
              <Typography className={classes.mobileActionText} variant="h4">
                {i18n.menu.donate}
              </Typography>
            </Box>
          </Link>
          <button onClick={openScanProductDialog} className={classes.button}>
            <Box id="scan-mobile" className={classes.mobileItemContainer}>
              <Image src={IconScan} alt="scan-icon" />
              <Typography className={classes.mobileActionText} variant="h4">
                {i18n.menu.scanProductBtn}
              </Typography>
            </Box>
          </button>

          <button onClick={toggleLanguage} className={classes.button}>
            <Box className={classes.mobileItemContainer}>
              <Typography
                variant="h4"
                className={clsx({
                  [classes.mobileLanguageText]: true,
                  [classes.languageTextEn]: language === 'en',
                  [classes.mobileLanguageTextEs]: language === 'es',
                })}
              >
                EN | ES
              </Typography>
            </Box>
          </button>
        </Box>
      </Collapse>
    </Box>
  )
}
