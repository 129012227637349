import {
  Box,
  CircularProgress,
  LinearProgress,
  Link,
  Typography,
  makeStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery, useQuery } from 'react-query'

import Api from 'api'
import { useDialog } from 'components/DialogsContext'
import { useLanguageContext } from 'components/LanguageContext'
import slugify from 'components/slugify'
import parseHtml from 'components/parseHtml'
import { dateStrToLocal, getNewsFeedTexts } from '../utils'

import IconCalendar from './assets/icon_calendar.png'
import IconLink from './assets/icon_link.png'

const useStyles = makeStyles(theme => ({
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    height: 35,
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
    },
  },
  feedContent: {
    cursor: 'pointer',
  },
  feedItemHeaderContainer: {
    display: 'flex',
    marginBottom: 10,
    [theme.breakpoints.down(425)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  headerItem: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      margin: '5px 0px',
    },
    [theme.breakpoints.down('400')]: {
      width: '100%',
    },
  },
  headerText: {
    lineHeight: '11px',
    marginLeft: 5,
    marginRight: 10,
  },
  horizontalLine: {
    borderBottom: '1px solid #EDF3FC',
    marginTop: 5,
    marginBottom: 10,
    width: '100%',
  },
  innerContainer: {
    height: '90%',
    overflowY: 'auto',
  },
  innerContainerLg: {
    height: '100%',
    overflowY: 'auto',
  },
  outerContainer: {
    backgroundColor: 'white',
    borderRadius: 30,
    height: 360,
    padding: '20px 10px 10px 20px',
  },
  outerContainerLg: {
    padding: '20px 10px 20px 20px',
  },
  outerContainerLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  switch: {
    'position': 'relative',
    'display': 'inline-block',
    'width': 37,
    'height': 24,
    'marginRight': 10,
    '& input': {
      opacity: 0,
      width: 0,
      height: 0,
    },
  },
  slider: {
    'position': 'absolute',
    'cursor': 'pointer',
    'top': 0,
    'left': 0,
    'right': 0,
    'bottom': 0,
    'backgroundColor': '#ccc',
    'transition': '.4s',
    'borderRadius': 34,
    '&:before': {
      position: 'absolute',
      content: "''",
      height: 21,
      width: 21,
      left: 2,
      bottom: 2,
      backgroundColor: 'white',
      transition: '.4s',
      borderRadius: '50%',
    },
  },
  title: {
    marginLeft: 12,
  },
  shouldHaveTipsActive: {
    'backgroundColor': theme.palette.primary.main,
    '&:before': {
      transform: 'translateX(12px)',
    },
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
  },
  loader: {
    width: '100%',
    height: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  source: {
    'height': '10%',
    'display': 'flex',
    'justifyContent': 'flex-end',
    'marginRight': 15,
    '& div, a': {
      display: 'flex',
      alignItems: 'center',
    },
    '& img': {
      width: 28,
      height: 28,
      margin: '0 5px',
    },
  },
}))

const truncateBody = str => {
  return str.length > 1200 ? str.substring(0, 1200) + '...' : str
}

function FeedItem({ feed, showLine, index }) {
  const { openFeedDialog } = useDialog()
  const { language } = useLanguageContext()
  const router = useRouter()

  const classes = useStyles()

  const onFeedPress = React.useCallback(() => {
    router.push(`news/${feed.id}/${slugify(feed.title)}`, undefined, {
      locale: language,
      shallow: true,
    })

    openFeedDialog(feed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, feed, openFeedDialog])

  return (
    <>
      <Box>
        <Box className={classes.feedItemHeaderContainer}>
          <Box className={classes.headerItem}>
            <Image src={IconCalendar} alt="calendar-icon" />
            <Typography
              variant="caption"
              color="textPrimary"
              className={classes.headerText}
            >
              {dateStrToLocal(feed.feed_date)}
            </Typography>
          </Box>
          <Box className={classes.headerItem}>
            <Image src={IconLink} alt="link-icon" />
            <Typography variant="caption" className={classes.headerText} noWrap>
              <Link
                color="textPrimary"
                href={`http://${feed.source_url}`}
                target="_blank"
              >
                {feed.source_url}
              </Link>
            </Typography>
          </Box>
        </Box>
        <Box
          onClick={onFeedPress}
          className={classes.feedContent}
          id={`feed_${index}`}
        >
          <Typography variant="subtitle2" color="textPrimary">
            {feed.title}
          </Typography>
          <Typography variant="caption">
            {parseHtml(truncateBody(feed.content))}
          </Typography>
        </Box>
      </Box>
      {showLine && <Box className={classes.horizontalLine} />}
    </>
  )
}

function Feed(props) {
  const { i18n, language } = useLanguageContext()
  const [shouldHaveTips, setShouldHaveTips] = React.useState(false)

  const { isLoading: isLoadingPages, data: pagesData } = useQuery({
    queryKey: ['pages', 'newsfeed_credit'],
    queryFn: () => Api.getPages({ slug: 'newsfeed_credit' }),
  })

  const {
    isLoading: isLoadingNews,
    data: feedData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['news', shouldHaveTips],
    queryFn: async ({ pageParam }) =>
      Api.getNews({ shouldHaveTips, offset: pageParam }),
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.total <= allPages.length * 20) {
        return undefined
      }
      return allPages.length * 20
    },
  })

  const feed = React.useMemo(() => {
    return !feedData
      ? {
          items: [],
          count: 0,
        }
      : {
          items: [].concat(...feedData.pages.map(page => page.items)),
          count: Number(feedData.pages[0].total),
        }
  }, [feedData])

  const newsFeedCredit = React.useMemo(() => {
    return (
      pagesData?.pages.find(x => x.language === language) ||
      pagesData?.pages.find(x => x.language === 'en')
    )
  }, [pagesData, language])

  const onToggleFeedWithTips = React.useCallback(e => {
    document.getElementById('feed-container').scrollTo(0, 0)

    const shouldHaveTips = e.target.checked
    setShouldHaveTips(shouldHaveTips)
  }, [])

  const renderFeedItem = React.useCallback(
    (item, index) => {
      const feedText = getNewsFeedTexts(item, language)
      const feedItem = {
        ...item,
        title: feedText.push_title,
        content: feedText.push_body,
        body: feedText.inapp_body
      }

      return (
        <FeedItem
          key={item.id}
          feed={feedItem}
          showLine={index < feed.items.length - 1}
          index={index}
        />
      )
    },
    [feed, language]
  )

  const classes = useStyles(props)
  const isLoading = isLoadingPages || isLoadingNews

  return (
    <>
      <Box className={classes.headerContainer}>
        <Typography
          variant="h3"
          color="textSecondary"
          className={classes.title}
        >
          {i18n.newsFeed.title}
        </Typography>
        <Box className={classes.filterContainer}>
          <label id="feedToggle" className={classes.switch}>
            <input type="checkbox" onChange={onToggleFeedWithTips} />
            <span
              id="feedToggleSlider"
              className={clsx({
                [classes.slider]: true,
                [classes.shouldHaveTipsActive]: shouldHaveTips,
              })}
            ></span>
          </label>
          <Typography variant="caption" className={classes.headerText}>
            {i18n.newsFeed.showNewsWithTips}
          </Typography>
        </Box>
      </Box>

      <Box
        className={clsx({
          [classes.outerContainer]: true,
          [classes.outerContainerLg]: !newsFeedCredit,
          [classes.outerContainerLoading]: isLoading,
        })}
      >
        <Box
          id="feed-container"
          className={clsx({
            [classes.innerContainer]: !!newsFeedCredit && !isLoadingNews,
            [classes.innerContainerLg]: !newsFeedCredit,
          })}
        >
          {!isLoadingNews && (
            <InfiniteScroll
              dataLength={feed.items.length}
              next={fetchNextPage}
              hasMore={hasNextPage}
              scrollableTarget="feed-container"
              loader={<LinearProgress />}
            >
              {feed.items.map(renderFeedItem)}
            </InfiniteScroll>
          )}
        </Box>
        {isLoadingNews && (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}
        {newsFeedCredit && (
          <Box className={classes.source}>
            {parseHtml(newsFeedCredit.content)}
          </Box>
        )}
      </Box>
    </>
  )
}

export default function NewsFeed() {
  return <Feed classes={{ shouldHaveTipsActive: 'shouldHaveTipsActive' }} />
}
