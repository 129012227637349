import axios from 'axios'
import { useRouter } from 'next/router'

import BackdropProgress from 'components/BackdropProgress'
import Home from 'components/Home'
import slugify from 'components/slugify'

export default function Index({ tip, feed }) {
  const router = useRouter()

  if (router.isFallback) {
    return <BackdropProgress />
  }

  if (tip) {
    return <Home tip={tip} />
  }

  if (feed) {
    return <Home feed={feed} />
  }

  return <Home />
}

async function getTipsPaths() {
  const { data } = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}/webdm/tips-directory`
  )

  let paths = []
  if (data.tips && data.tips.length > 0) {
    paths = data.tips.flatMap(tip => [
      {
        params: {
          slug: ['tips', tip.id.toString()],
        },
      },
      {
        params: {
          slug: ['tips', tip.id.toString(), slugify(tip.tip)],
        },
      },
    ])
  }

  return paths
}

async function getNewsFeedPaths() {
  const { data } = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}/webdm/news-directory`
  )

  let paths = []
  if (data.news && data.news.length > 0) {
    paths = data.news.flatMap(newsItem => {
      const props = [
        {
          params: {
            slug: ['news', newsItem.id.toString()],
          },
        },
      ]

      const newsText = newsItem.texts.find(x => x.language === 'en')

      if (newsText) {
        props.push({
          params: {
            slug: [
              'news',
              newsItem.id.toString(),
              slugify(newsText.push_title),
            ],
          },
        })
      }
      return props
    })
  }

  return paths
}

export async function getStaticPaths() {
  const tipPaths = await getTipsPaths()
  const newsFeedPaths = await getNewsFeedPaths()

  return {
    paths: [...tipPaths, ...newsFeedPaths],
    fallback: true,
  }
}

const emptyProps = { props: {} }
const notFound = { notFound: true }

async function getTipProps(tipId) {
  const { data } = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}/webdm/tips/${tipId}`
  )

  if (data.tip) {
    return {
      props: {
        tip: data.tip,
      },
      revalidate: 8 * 60 * 60,
    }
  }

  return notFound
}

async function getNewsFeedProps(newsFeedItemId) {
  const { data } = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}/webdm/news/${newsFeedItemId}`
  )

  if (data.pushitem) {
    return {
      props: {
        feed: data.pushitem,
      },
    }
  }

  return notFound
}

export async function getStaticProps({ params }) {
  const { slug } = params

  if (!slug || !Array.isArray(slug) || slug.length < 2) {
    return emptyProps
  }

  const contentType = slug[0]
  const contentId = slug[1]
  const isValidContentId = parseInt(contentId) !== 'NaN'

  if ((contentType !== 'tips' && contentType !== 'news') || !isValidContentId) {
    return notFound
  }

  if (contentType === 'tips') {
    return await getTipProps(contentId)
  }

  if (contentType === 'news') {
    return await getNewsFeedProps(contentId)
  }

  return emptyProps
}
